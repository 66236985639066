<template>
    <div class="toolbar_main">
        <div class="osahan-breadcrumb">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12 col-md-12">
                    <ol class="breadcrumb">
                        <li class="breadcrumb-item"><a :href="'/tienda/'+sucursal_seleccionada"><i class="icofont icofont-ui-home"></i>Inicio</a></li>
                        <li class="breadcrumb-item active">Descargar comprobante electrónico</li>
                    </ol>
                    </div>
                </div>
            </div>
        </div>

        <div class="container">
            <div class="row row-height mb-4">
                <div class="col-md-5 offset-md-3">
                    <div class="card">
                        <div class="card-header"><h3 class="card-title">Descargue su comprobante</h3></div>
                        <div class="card-body">
                            <p v-if="resultado.codigo_unico==''">No se han encontrado resultados.</p>
                            <div v-else>
                                <div class="row">
                                    <div class="col-md-6">
                                        <PdfThumbnail :fileUrl="base_url+'tiendaVirtual/comprobante_digital/pdf/'+resultado.codigo_unico" :width="120" :height="200" :target="'_blank'"></PdfThumbnail>
                                        <button :class="['btn', 'mt-2', 'btn-danger', 'cursor-pointer']" @click="descargarComprobante(base_url+'tiendaVirtual/comprobante_digital/pdf/'+resultado.codigo_unico, resultado.comprobante+'.pdf')">
                                            <i class="fa fa-download ml-2" aria-hidden="true"></i>
                                            Descargar
                                        </button>
                                    </div>
                                    
                                    <div class="col-md-6">
                                        <a href="javascript:void(0)">
                                            <img src="/img/xml_thumbnails.png" alt="..." style="width:120px; height:202px; cursor: default;" class="img-thumbnail mb-3">
                                        </a>
                                        <button :class="['btn', 'btn-danger', 'cursor-pointer']" @click="descargarComprobante(base_url+'tiendaVirtual/comprobante_digital/xml/'+resultado.codigo_unico, resultado.comprobante+'.xml')">
                                            <i class="fa fa-download ml-2" aria-hidden="true"></i>
                                            Descargar
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import axios from 'axios';
    import {mapState, mapMutations, mapActions} from 'vuex';
    import PdfThumbnail from '@/components/PdfThumbnail.vue';
    export default {
        name: 'Download',
        computed:{
            ...mapState(['loading','empresa', 'sucursal_seleccionada']),
        },
        components: {
            PdfThumbnail,
		},
        created(){
            this.loadObjEmpresa();
            this.loadObjSucursalSeleccionada();

            this.consultarComprobante(this.$route.params.codigo_unico);
        },
        mounted(){
            this.loadObjEmpresa();
            document.title = "Bienvenido a la "+((this.empresa.abreviatura)?this.empresa.abreviatura:"CLARO");
        },
        data(){
            return{
                resultado:{
                    codigo_unico:this.$route.params.codigo_unico,
                    comprobante:""
                },
                base_url: `${window.baseUrl}`,
            }
        },
        methods:{
            ...mapMutations(['setLoading']),
            ...mapActions(['loadObjEmpresa', 'loadObjSucursalSeleccionada']),
            consultarComprobante(codigo_unico){
                this.setLoading(true);

                this.$http.get('tiendaVirtual/verificar_comprobante/'+codigo_unico).then((res)=>{
                    console.log(res.data);
                    if(res.data.result!=""){
                        this.resultado.codigo_unico = res.data.codigo_unico;
                        this.resultado.comprobante  = res.data.comprobante;
                    }else{
                        this.resultado.codigo_unico = "";
                        this.resultado.comprobante  = "";
                    }
                }).finally(()=>{
                    this.setLoading(false);
                });
            },
            descargarComprobante(file, label){
                this.setLoading(true);
                axios({
                    url: file,
                    method: 'GET',
                    responseType: 'blob', // important
                }).then((response) => {
                    const url = window.URL.createObjectURL(new Blob([response.data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', label);
                    document.body.appendChild(link);
                    link.click();
                    this.setLoading(false);
                });
            },
        },
    }
</script>
